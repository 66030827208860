/* eslint-disable brace-style */

import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { OAuthErrorEvent, OAuthService, TokenResponse } from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest, firstValueFrom, from, merge, of } from 'rxjs';
import { catchError, debounceTime, exhaustMap, filter, first, map, share, shareReplay, skipWhile, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AuthenticationToken, User } from './models';
import { setUserData } from './pages/login/store/auth.actions';
import { DataleanDataProviderService } from './provider/datalean-data-provider.service';
import { UserSettingsService } from './services';
import { AuthActions } from './store/app.actions';

@Injectable({ providedIn: 'root' })
export class AuthService implements OnDestroy {
  private isDestroyed = new Subject();

  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.pipe(shareReplay(1),takeUntil(this.isDestroyed));

  private isDoneLoadingSubject$ = new BehaviorSubject<boolean>(false);
  public isDoneLoading$ = this.isDoneLoadingSubject$.pipe(shareReplay(1),takeUntil(this.isDestroyed));

  /**
   * Publishes `true` if and only if (a) all the asynchronous initial
   * login calls have completed or errorred, and (b) the user ended up
   * being authenticated.
   *
   * In essence, it combines:
   *
   * - the latest known state of whether the user is authorized
   * - whether the ajax calls for initial log in have all been done
   */

  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([this.isAuthenticated$, this.isDoneLoading$]).pipe(
    map((values) => values.every((b) => b)),
    takeUntil(this.isDestroyed)
  );

  private navigateToLoginPage() {
    // TODO: Remember current URL
    this.router.navigateByUrl('/login');
  }

    private autoRefreshSubs?: Subscription;
      stopAutomaticRefresh() {
          this.autoRefreshSubs?.unsubscribe();
      }
      startAutomaticRefresh() {
          if (this.autoRefreshSubs) {
              this.stopAutomaticRefresh();
          }
          this.autoRefreshSubs = this.oauthService.events
              .pipe(
                  tap(console.log),
                  filter((e) => 'token_expires' === e.type && e.info === 'access_token'),
                  switchMap(() => this.tryToRefreshToken()),
              )
              .subscribe(() => {});
      }

  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private store: Store,
    private dataleanService: DataleanDataProviderService,
    private userSettingService: UserSettingsService,
    private ngZone: NgZone
    ) {
        document.addEventListener('resume', () => {
          this.ngZone.run(() => {
                this.isDoneLoadingSubject$.next(false);
                this.tryToRefreshToken().pipe(first()).subscribe(() => {});
            });
        });

    // Useful for debugging:
    // this.oauthService.events.pipe(takeUntil(this.isDestroyed)).subscribe((event) => {
    //   if (event instanceof OAuthErrorEvent) {
    //     console.error('OAuthErrorEvent Object:', event);
    //   } else {
    //     console.debug('OAuthEvent Object:', event);
    //   }
    // });


    if(sessionStorage.getItem('logout') === 'true') {
      sessionStorage.clear();
      this.isDoneLoadingSubject$.next(true);
      this.navigateToLoginPage();
    }

    // This is tricky, as it might cause race conditions (where access_token is set in another
    // tab before everything is said and done there.
    // TODO: Improve this setup. See: https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards/issues/2
    window.addEventListener('storage', (event) => {
      // console.log('Received storage event', event);

      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }

      console.warn('Noticed changes to access_token (most likely from another tab), updating isAuthenticated');
      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

      if (!this.oauthService.hasValidAccessToken()) {
        this.navigateToLoginPage();
      }
    });

    this.oauthService.events.pipe(takeUntil(this.isDestroyed)).subscribe(() => {
      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
    });

    this.oauthService.events
      .pipe(
        filter((e) => ['session_terminated', 'session_error', ''].includes(e.type)),
        takeUntil(this.isDestroyed)
      )
      .subscribe(() => this._logout());

    this.oauthService.events
      .pipe(
        filter((e) => ['token_revoke_error', 'token_refresh_error'].includes(e.type)),
        takeUntil(this.isDestroyed),
        switchMap(() => this.isAuthenticated$),
        skipWhile((isAuthenticated) => !isAuthenticated)
      )
      .subscribe(() => this._logout());

    this.oauthService.events.pipe(filter((e) => ['token_received'].includes(e.type)),debounceTime(1000)).subscribe(() => {
      const token = new AuthenticationToken(this.oauthService.getIdToken(), undefined);
      const userUUID = token.getPayloadParameter('userUUID');
      this.dataleanService
        .getUserWithUUID(userUUID)
        .pipe(
          catchError((authError) => {
            this.store.dispatch(AuthActions.setAuthError({ authError }));
            return of(undefined);
          })
        )
        .subscribe((userData: User | undefined) => {
          if (userData) {
            this.store.dispatch(setUserData({ userData }));
          }
          this.isDoneLoadingSubject$.next(true);
        });
    });

    this.startAutomaticRefresh();
  }

  ngOnDestroy() {
    this.isDestroyed.next(true);
    this.isDestroyed.complete();
  }

  public runInitialLoginSequence(callbackURI?: string): Promise<void> {
    if (location.hash) {
      console.log('Encountered hash fragment, plotting as table...');
      console.table(
        location.hash
          .substr(1)
          .split('&')
          .map((kvp) => kvp.split('='))
      );
    }

    // 0. LOAD CONFIG:
    // First we have to check to see how the IdServer is
    // currently configured:
    return (
      this.oauthService
        .loadDiscoveryDocument()

        // For demo purposes, we pretend the previous call was very slow
        //.then(() => new Promise<void>(resolve => setTimeout(() => resolve(), 1000)))

        // 1. HASH LOGIN:
        // Try to log in via hash fragment after redirect back
        // from IdServer from initImplicitFlow:
        .then(() => this.oauthService.tryLogin())

        .then(() => {
          if (this.oauthService.hasValidIdToken() && this.oauthService.hasValidAccessToken()) {
            const token = new AuthenticationToken(this.oauthService.getIdToken(), undefined);
            const userUUID = token.getPayloadParameter('userUUID');
            this.dataleanService
              .getUserWithUUID(userUUID)
              .pipe(
                catchError((authError) => {
                  this.store.dispatch(AuthActions.setAuthError({ authError }));
                  return of(undefined);
                })
              )
              .subscribe((userData: User | undefined) => {
                if (userData) {
                  this.store.dispatch(setUserData({ userData }));
                }
                this.isDoneLoadingSubject$.next(true);
              });
            return Promise.resolve();
          }

          // 2. SILENT LOGIN:
          // Try to log in via a refresh because then we can prevent
          // needing to redirect the user:
           return firstValueFrom(this.tryToRefreshToken())
          // return this.oauthService
          // .refreshToken()
            .then(() => {Promise.resolve()})
            .catch((result) => {
              // Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
              // Only the ones where it's reasonably sure that sending the
              // user to the IdServer will help.
              const errorResponsesRequiringUserInteraction = [
                'interaction_required',
                'login_required',
                'account_selection_required',
                'consent_required',
              ];

              if (result && result.reason && errorResponsesRequiringUserInteraction.indexOf(result.reason.error) >= 0) {
                // 3. ASK FOR LOGIN:
                // At this point we know for sure that we have to ask the
                // user to log in, so we redirect them to the IdServer to
                // enter credentials.
                //
                // Enable this to ALWAYS force a user to login.
                // this.login();
                //
                // Instead, we'll now do this:
                console.warn('User interaction is needed to log in, we will wait for the user to manually log in.');
                return Promise.resolve();
              }

              // We can't handle the truth, just pass on the problem to the
              // next handler.
              return Promise.reject(result);
            });
        })

        .then(() => {
          // Check for the strings 'undefined' and 'null' just to be sure. Our current
          // login(...) should never have this, but in case someone ever calls
          // initImplicitFlow(undefined | null) this could happen.
          if (this.oauthService.state && this.oauthService.state !== 'undefined' && this.oauthService.state !== 'null') {
            let stateUrl = this.oauthService.state || callbackURI;
            if (stateUrl.startsWith('/') === false) {
              stateUrl = decodeURIComponent(stateUrl);
            }
            console.log(`There was state of ${this.oauthService.state}, so we are sending you to: ${stateUrl}`);
            this.router.navigateByUrl(stateUrl);
          }
        })
        .catch((e) => {
          console.error(e);
          // console.log(this.oauthService.state, callbackURI);

          this.isDoneLoadingSubject$.next(true);
          this.login(this.oauthService.state || callbackURI);
        })
    );
  }

  public login(targetUrl?: string) {
    // Note: before version 9.1.0 of the library you needed to
    // call encodeURIComponent on the argument to the method.
    this.oauthService.initLoginFlow(targetUrl || this.router.url);
  }

  public async logout(remote?) {
    this.isDoneLoadingSubject$.next(true);
    if (remote) {
      sessionStorage.setItem('logout', 'true');
      return await this.oauthService.revokeTokenAndLogout();
    }
    this._logout();
    sessionStorage.setItem('logout', 'true');
  }

  private _logout() {
    this.clearStorage();
    this.oauthService.logOut(true);
    this.navigateToLoginPage();
  }

  public hasValidToken() {
    return this.oauthService.hasValidAccessToken();
  }

  private clearStorage() {
    sessionStorage.clear();
  }

    private _exchangeToken$ = new Subject<boolean>();

    private _refreshToken$ = this._exchangeToken$.pipe(
        filter((x) => !!x),
        exhaustMap((_) => from(this.oauthService.refreshToken())),
        share(),
    );

    tryToRefreshToken(): Observable<TokenResponse | null> {
        //this.isDoneLoadingSubject$.next(false);
        const startToken$: Observable<any> = of(true).pipe(
            tap((x) => this._exchangeToken$.next(x)),
            filter((_) => false),
        );
        return merge(this._refreshToken$.pipe(first()), startToken$).pipe(
            // catchError(() => {
            //     this.isDoneLoadingSubject$.next(true);
            //     return of(null);
            // }),
        );
    }


  // These normally won't be exposed from a service like this, but
  // for debugging it makes sense.
  public get accessToken() {
    return this.oauthService.getAccessToken();
  }

  public get refreshToken() {
    return this.oauthService.getRefreshToken();
  }

  public get identityClaims() {
    return this.oauthService.getIdentityClaims();
  }

  public get idToken() {
    return this.oauthService.getIdToken();
  }

  public get logoutUrl() {
    return this.oauthService.logoutUrl;
  }
}
